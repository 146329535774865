import './App.css'
import { useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'
import { PlusIcon } from './icons/plus'
import { WebApp } from '@grammyjs/web-app'

WebApp.expand()
// WebApp.MainButton.setText('Отправить')
// WebApp.MainButton.disable()
// WebApp.MainButton.show()

const serverUrl = 'https://themebotbot.maonymous.ru'
// const serverUrl = 'https://2725-176-110-129-69.ngrok-free.app'

const locales = {
  ru: {
    select: 'Выбрать',
    tap: 'Нажмите',
    send: 'Отправить',
  },
  en: {
    select: 'Select',
    tap: 'Click',
    send: 'Send',
  },
  uk: {
    select: 'Вибрати',
    tap: 'Натисніть',
    send: 'Відправити',
  },
}

const query = new URLSearchParams(window.location.search)
const lang = query.get('lang') as 'ru' | 'en' | 'uk'
const locale = locales[lang]
const fileId = query.get('fileId')
const userId = query.get('userId')
const imageUrl = `${serverUrl}/pair/file/${fileId}`

function App() {
  const [isResizing, setIsResizing] = useState(false)
  const cropperRef = useRef<HTMLImageElement>(null)
  const [photo, setPhoto] = useState<any>('')
  const [avatarLeft, setAvatarLeft] = useState<any>(null)
  const [avatarRight, setAvatarRight] = useState<any>(null)
  const [currentResizeImage, setCurrentResizeImage] = useState(1)

  useEffect(() => {
    if (photo) return
    const fetchPhoto = async () => {
      const imageBlob = (await axios.get(imageUrl, { responseType: 'blob' }))
        .data
      const image = URL.createObjectURL(imageBlob)
      setPhoto(image)
      WebApp.ready()
    }

    fetchPhoto()
  })

  const onCrop = () => {}

  const onClickImage = (index: number) => {
    setCurrentResizeImage(index)
    setIsResizing(true)
    // WebApp.MainButton.hide()
  }

  const onConfirmResize = () => {
    setIsResizing(false)
    const imageElement: any = cropperRef?.current
    const cropper: Cropper = imageElement?.cropper
    const canvas = cropper.getCroppedCanvas()
    const image = canvas.toDataURL()

    if (currentResizeImage == 1) {
      setAvatarLeft(image)
      if (avatarRight) WebApp.MainButton.enable()
    }
    if (currentResizeImage == 2) {
      setAvatarRight(image)
      if (avatarLeft) WebApp.MainButton.enable()
    }
    // WebApp.MainButton.show()
  }

  const confirmButton = async () => {
    if (!avatarLeft || !avatarRight) return
    const data = new FormData()
    data.append('left', avatarLeft)
    data.append('right', avatarRight)

    await axios.post(`${serverUrl}/confirm-pairthemes/${userId}/${lang}`, data)
    WebApp.close()
  }

  WebApp.MainButton.onClick(confirmButton)

  const CropPhotoComponent = () => {
    return (
      <div className="crop-photo-area">
        <Cropper
          src={photo}
          style={{
            height: 400,
            width: '100%',
            backgroundColor: 'var(--tg-theme-bg-color)',
          }}
          initialAspectRatio={16 / 9}
          dragMode="crop"
          viewMode={1}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
          background={false}
        />

        <button className="crop-photo-button" onClick={() => onConfirmResize()}>
          {locale.select}
        </button>
      </div>
    )
  }

  const MainComponent = () => {
    return (
      <div>
        <div className="avatar-selector">
          <div>
            <div className="avatar avatar-left" onClick={() => onClickImage(1)}>
              {avatarLeft === null ? (
                <PlusIcon />
              ) : (
                <img src={avatarLeft} alt="" />
              )}
            </div>
            <div className="under-avatar-area avatar-left">{locale.tap}</div>
          </div>
          <div>
            <div
              className="avatar avatar-right"
              onClick={() => onClickImage(2)}
            >
              {avatarRight === null ? (
                <PlusIcon />
              ) : (
                <img src={avatarRight} alt="" />
              )}
            </div>
            <div className="under-avatar-area avatar-right">{locale.tap}</div>
          </div>
        </div>

        <div className="confirm-button-area">
          <button className="confirm-button" onClick={() => confirmButton()}>
            {locale.send}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="App">
      {isResizing ? <CropPhotoComponent /> : <MainComponent />}
    </div>
  )
}

export default App
